<template>
  <b-navbar-item v-if="isActive" class="mate-availability">
    <div class="media">
      <b-icon
        pack="fas"
        :icon="isFeatureOn ? 'circle' : ''"
        :class="['media-left', actualAvailabilityIconClass]"
      />
      <div class="media-content">
        <strong v-if="isFeatureOn">{{ localizedAvailibility }}</strong>
        <br v-if="isFeatureOn" />
        <p>{{ $t("mateAvailability.whenAmIAvailableNext") }}</p>

        <b-taglist>
          <b-tag
            v-for="availability in $mateAvailabilityService.table"
            :key="availability.key"
            :class="[
              getBackgroundClass(availability),
              'availability-tag',
              availability.buefyType,
            ]"
            @click="updateUnavailability(availability)"
          >
            {{ $t(`mateAvailability.${availability.key}`) }}
          </b-tag>
        </b-taglist>
      </div>
    </div>
  </b-navbar-item>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "pinia";
import { useUserStore } from "~/stores/user";

export default {
  name: "MateAvailabilityMenu",
  setup() {
    return {
      userStore: useUserStore(),
    };
  },
  computed: {
    ...mapState(useUserStore, {
      me: "me",
    }),
    showAvailability() {
      if (!this.me?.lastUnavailability?.trustDate) {
        return false;
      }
      const nowNr = dayjs();
      const trustDateNr = dayjs(this.me.lastUnavailability.trustDate);
      return trustDateNr.isAfter(nowNr);
    },
    isActive() {
      return !this.me?.isInactive;
    },
    isFeatureOn() {
      return !this.me?.isInactive && this.me?.hasUnavailability;
    },
    localizedAvailibility() {
      return this.$mateAvailabilityService.localizedAvailibility(
        this.me.lastUnavailability
      );
    },
    /**
     * Returns a buefy coloring class to fit templates .media-left icon
     */
    currentAvailability() {
      return this.$mateAvailabilityService.getAvailibility(
        this.me.lastUnavailability
      );
    },
    actualAvailabilityIconClass() {
      if (!this.showAvailability) {
        return "";
      }
      const availibility = this.currentAvailability;
      const colorClass = availibility?.buefyType;
      return colorClass ? `has-text-${colorClass}` : "";
    },
  },
  methods: {
    getBackgroundClass(availability) {
      return this.currentAvailability.key === availability.key
        ? `has-background-${availability.buefyType}`
        : "";
    },
    async updateUnavailability(availabilityCode) {
      if (this.isFeatureOn) {
        await this.userStore.updateUnavailability(availabilityCode).then(() => {
          this.$mateAvailabilityService.showAvailabilityChangeSuccesSnack(
            availabilityCode
          );
        });
        // TODO: error handling modals .catch()
      } else {
        this.$buefy.dialog.confirm({
          message: this.$t("mateAvailability.confirmDialog.message"),
          type: "is-info",
          hasIcon: true,
          icon: "info-circle",
          iconPack: "fa",
          ariaRole: "alertdialog",
          ariaModal: true,
          cancelText: this.$t("common.cancel"),
          confirmText: this.$t("common.accept"),
          onConfirm: async () => {
            await this.userStore.setHasUnavailability(true);
            this.updateUnavailability(availabilityCode);
          },
        });
      }
    },
  },
};
</script>
<style lang="scss">
@use "styles/_all-members" as global;

.mate-availability {
  &:hover {
    background: inherit !important;
  }

  .media {
    .media-content {
      overflow-y: hidden;
      .tag {
        font-weight: bold;
      }
    }
  }
}

.availability-tag {
  &.danger:hover {
    background-color: global.$danger;
  }

  &.warning:hover {
    background-color: global.$warning;
  }

  &.success:hover {
    background-color: global.$success;
  }

  &.info:hover {
    background-color: global.$info;
  }
}
</style>
