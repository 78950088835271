<template>
  <b-navbar-dropdown
    v-if="me"
    boxed
    right
    hoverable
    class="has-dropdown-expanded-mobile header-link"
  >
    <template #label>
      <img
        class="border-solid border-2 border-primary rounded-full inline-block w-7 h-7 align-middle mr-2"
        v-lazy-load :data-src="$helpers.getProfilePictureThumbnailUrl(me.id)"
        @error="$helpers.noImage"
      />
      {{ me.name }}
    </template>

    <b-navbar-item
      v-if="me.isAdmin"
      tag="NuxtLink"
      :to="localePath({ name: 'conductorIndexPage' })"
    >
      <div class="media">
        <b-icon pack="fas" icon="magic" class="media-left" />
        <div class="media-content font-bold">
          {{ $t("userMenu.conductor") }}
        </div>
      </div>
    </b-navbar-item>

    <hr v-if="me.isAdmin" class="navbar-divider" />

    <template v-if="me.isMate && !me.isInactive && $helpers.isDev()">
      <MateAvailability />

      <hr class="navbar-divider" />
    </template>

    <template
      v-if="me.isMate && (me.professions?.length || me.miniMarkets?.length)"
    >
      <b-navbar-item
        tag="NuxtLink"
        :to="localePath({ name: 'buyTendersPage' })"
      >
        <div class="media">
          <b-icon pack="fas" icon="gas-pump" class="media-left text-success" />
          <div class="media-content">
            <span class="font-bold">{{ $t("userMenu.buyTenders.title") }}</span>
            <br />
            <p>{{ $t("userMenu.buyTenders.subtitle") }}</p>
          </div>
        </div>
      </b-navbar-item>

      <hr class="navbar-divider" />
    </template>
    <b-navbar-item
      v-if="me.isMate"
      tag="NuxtLink"
      :to="localePath({ name: 'boughtTendersPage' })"
    >
      <div class="media">
        <b-icon pack="fas" icon="tasks" class="media-left text-info" />
        <div class="media-content">
          <span class="font-bold">{{
            $t("userMenu.boughtTenders.title")
          }}</span>
          <br />
          <p>{{ $t("userMenu.boughtTenders.subtitle") }}</p>
        </div>
      </div>
    </b-navbar-item>

    <hr v-if="me.isMate" class="navbar-divider" />
    <b-navbar-item
      v-if="me.isMate"
      tag="NuxtLink"
      :to="localePath({ name: 'addPricesPage' })"
    >
      <div class="media">
        <b-icon pack="fas" icon="tag" class="media-left has-text-warning" />
        <div class="media-content">
          <span class="font-bold">
            {{ $t("userMenu.prices.title") }}
            <b-tag v-if="!hasProfessionTaskPrice" type="is-danger">{{
              $t("userMenu.prices.addPrice")
            }}</b-tag>
          </span>
        </div>
      </div>
    </b-navbar-item>

    <hr v-if="me.isMate" class="navbar-divider" />
    <b-navbar-item
      v-if="me.isMate"
      tag="NuxtLink"
      :to="localePath({ name: 'subscriptionPage' })"
    >
      <div class="media">
        <b-icon pack="fas" icon="rocket" class="media-left text-info" />
        <div class="media-content">
          <span class="font-bold">{{ $t("userMenu.subscription.title") }}</span>
          <p v-if="me.validSubscriptions.length == 0" class="subtext">
            {{ $t("userMenu.subscription.subtitle") }}
          </p>
        </div>
      </div>
    </b-navbar-item>

    <b-navbar-item
      v-if="!me.isMate"
      tag="NuxtLink"
      :to="localePath({ name: 'registrationPage' })"
    >
      <div class="media">
        <b-icon pack="fas" icon="crown" class="media-left has-text-warning" />
        <div class="media-content">
          <span class="font-bold">{{ $t("userMenu.registration.title") }}</span>
          <p class="subtext">
            {{ $t("userMenu.registration.subtitle") }}
          </p>
        </div>
      </div>
    </b-navbar-item>

    <hr class="navbar-divider" />
    <b-navbar-item tag="NuxtLink" :to="localePath({ name: 'myTenders' })">
      <div class="media">
        <b-icon
          pack="fas"
          icon="couch"
          class="media-left has-text-grey-light"
        />
        <div class="media-content">
          <span class="font-bold">{{ $t("userMenu.myTenders.title") }}</span>
        </div>
      </div>
    </b-navbar-item>

    <!-- csak ajánlatkérőnek jelenik meg -->
    <b-navbar-item tag="NuxtLink" :to="localePath({ name: 'settingsPage' })">
      <div class="media">
        <b-icon pack="fas" icon="cog" class="media-left has-text-grey-light" />
        <div class="media-content">
          <span class="font-bold">{{ $t("userMenu.settings.title") }}</span>
        </div>
      </div>
    </b-navbar-item>

    <hr class="navbar-divider" />
    <a class="navbar-item" @click="logOut">
      <div class="media">
        <b-icon class="media-left has-text-grey-light" icon="sign-out-alt" />
        <div class="media-content">
          <strong>{{ $t("userMenu.logout") }}</strong>
        </div>
      </div>
    </a>
  </b-navbar-dropdown>
</template>

<script>
import { mapState } from "pinia";
import MateAvailability from "~/components/menu/MateAvailability.vue";
import { useUserStore } from "~/stores/user";
import { useLogout } from "~/components/_refactored/auth";

export default {
  components: {
    MateAvailability,
  },
  setup() {
    return {
      logout: useLogout(),
    };
  },
  computed: {
    ...mapState(useUserStore, {
      me: "me",
    }),
    hasProfessionTaskPrice() {
      if (this.me && this.me.prices) {
        return this.me.prices.find((price) => {
          return !price.deleted && price.professionTask;
        });
      } else {
        return null;
      }
    },
  },
  methods: {
    emitClickEvent() {
      this.$emit("click");
    },
    logOut() {
      this.emitClickEvent();
      this.logout();
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
@use "styles/_all-members" as global;

.media {
  pointer-events: none; // so clicks on 'empty' areas are still registered in the dropdown
}

.subtext {
  white-space: normal;
}

// NOTE: revisit scoping later https://vue-loader.vuejs.org/guide/scoped-css.html#child-component-root-elements and https://vuejs.org/v2/style-guide/#Component-style-scoping-essential
.has-dropdown-expanded-mobile {
  &::v-deep {
    @include global.touch {
      .navbar-link {
        &::after {
          display: none;
        }
      }

      .navbar-dropdown {
        visibility: visible;

        .navbar-item {
          padding-left: 0.75rem;
          padding-right: 0.75rem;
        }
      }
    }
  }
}
</style>
